var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" Manajemen Diet Coaching & Challenge ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "mb": "33px",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" Manajemen Diet Coaching & Challenge ")]), _c('c-button', {
    attrs: {
      "h": "auto",
      "py": "12px",
      "px": "28px",
      "color": "#927102",
      "border-radius": "24px",
      "border-color": "transparent",
      "bg": "secondary.800",
      "variant-color": "inherit",
      "font-size": "16px",
      "line-height": "24px",
      "variant": "solid",
      "as": "router-link",
      "to": {
        name: 'superadmin.coaching-challenge.add',
        params: {
          type: _vm.type
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-plus.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Tambah Konten ")], 1)], 1), _c('c-box', {
    attrs: {
      "background": "#FFFFFF",
      "border": "1px solid #F2F2F2",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.05)",
      "border-radius": "12px"
    }
  }, [_c('c-flex', {
    attrs: {
      "py": "25px",
      "px": "27px",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "flex-grow": "1"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "600",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" List of Diet Coaching & Challenge ")]), _c('c-menu', {
    attrs: {
      "close-on-select": false
    }
  }, [_c('c-menu-button', {
    attrs: {
      "border-radius": "40px",
      "background-color": "superLightGray.900",
      "pl": "20px",
      "pr": "30px",
      "py": "10px",
      "h": "auto",
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px",
      "font-family": "Roboto",
      "font-weight": "normal",
      "display": ['none', 'flex']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  }), _vm._v(" Filter ")], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "240px"
    }
  }, [_c('c-menu-option-group', {
    attrs: {
      "default-value": "Semua",
      "type": "radio"
    },
    on: {
      "change": _vm.onChangeFilter
    }
  }, _vm._l(_vm.filterItems, function (item) {
    return _c('c-menu-item-option', {
      key: item.value,
      attrs: {
        "value": item.label,
        "border-bottom": "1px solid #F2F2F2",
        "font-size": "18px",
        "line-height": "27px",
        "py": "16px",
        "px": "20px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1)], 1), _c('c-divider', {
    attrs: {
      "mx": "23px",
      "orientation": "vertical"
    }
  }), _c('c-box', {
    attrs: {
      "flex-basis": "248px",
      "align-self": "center"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "pos": "absolute",
      "top": "10px",
      "left": "15px",
      "z-index": "2"
    }
  }), _c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '&::placeholder': {
          color: 'gray.900'
        }
      },
      expression: "{\n              '&::placeholder': {\n                color: 'gray.900',\n              },\n            }"
    }],
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "placeholder": "Pencarian",
      "background": "superLightGray.900",
      "border-radius": "6px",
      "padding-left": "50px",
      "pos": "relative",
      "z-index": "1"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('c-box', [_c('c-flex', [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '&:hover': {
          background: '#F2F2F2',
          borderRadius: '0'
        },
        '&:focus, &:active': {
          color: 'primary.400',
          background: 'white',
          borderRadius: '0',
          boxShadow: 'none'
        }
      },
      expression: "{\n            '&:hover': {\n              background: '#F2F2F2',\n              borderRadius: '0',\n            },\n            '&:focus, &:active': {\n              color: 'primary.400',\n              background: 'white',\n              borderRadius: '0',\n              boxShadow: 'none',\n            },\n          }"
    }],
    attrs: {
      "size": "md",
      "height": "58px",
      "flex-grow": "1",
      "color": _vm.type === 'daily_coaching' ? 'primary.400' : 'darkGray.900',
      "font-size": "16px",
      "line-height": "24px",
      "border-radius": "0",
      "background": _vm.type === 'daily_coaching' ? 'white' : 'superLightGray.900',
      "border-color": "green.500"
    },
    on: {
      "click": function click($event) {
        return _vm.loadContentChallengeByType('daily_coaching');
      }
    }
  }, [_c('c-image', {
    staticClass: "icon",
    attrs: {
      "alt": "daily",
      "src": _vm.type === 'daily_coaching' ? require('@/assets/icon-calendar-event-active.svg') : require('@/assets/icon-calendar-event.svg'),
      "w": "24px",
      "h": "24px",
      "mr": [null, '22px']
    }
  }), _vm._v(" Daily Coaching ")], 1), _c('c-divider', {
    attrs: {
      "m": "0",
      "border-color": "lightGray",
      "orientation": "vertical"
    }
  }), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '&:hover': {
          background: '#F2F2F2',
          borderRadius: '0'
        },
        '&:focus, &:active': {
          color: 'primary.400',
          background: 'white',
          borderRadius: '0',
          boxShadow: 'none'
        }
      },
      expression: "{\n            '&:hover': {\n              background: '#F2F2F2',\n              borderRadius: '0',\n            },\n            '&:focus, &:active': {\n              color: 'primary.400',\n              background: 'white',\n              borderRadius: '0',\n              boxShadow: 'none',\n            },\n          }"
    }],
    attrs: {
      "size": "md",
      "height": "58px",
      "flex-grow": "1",
      "color": _vm.type === 'coaching_course' ? 'primary.400' : 'darkGray.900',
      "font-size": "16px",
      "line-height": "24px",
      "border-radius": "0",
      "background": _vm.type === 'coaching_course' ? 'white' : 'superLightGray.900',
      "border-color": "green.500"
    },
    on: {
      "click": function click($event) {
        return _vm.loadContentChallengeByType('coaching_course');
      }
    }
  }, [_c('c-image', {
    staticClass: "icon",
    attrs: {
      "alt": "daily",
      "src": _vm.type === 'coaching_course' ? require('@/assets/icon-target-active.svg') : require('@/assets/icon-target.svg'),
      "w": "24px",
      "h": "24px",
      "mr": [null, '22px']
    }
  }), _vm._v(" Coaching Course ")], 1), _c('c-divider', {
    attrs: {
      "m": "0",
      "border-color": "lightGray",
      "orientation": "vertical"
    }
  }), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '&:hover': {
          background: '#F2F2F2',
          borderRadius: '0'
        },
        '&:focus, &:active': {
          color: 'primary.400',
          background: 'white',
          borderRadius: '0',
          boxShadow: 'none'
        }
      },
      expression: "{\n            '&:hover': {\n              background: '#F2F2F2',\n              borderRadius: '0',\n            },\n            '&:focus, &:active': {\n              color: 'primary.400',\n              background: 'white',\n              borderRadius: '0',\n              boxShadow: 'none',\n            },\n          }"
    }],
    attrs: {
      "size": "md",
      "height": "58px",
      "flex-grow": "1",
      "color": _vm.type === 'diet_challenge' ? 'primary.400' : 'darkGray.900',
      "font-size": "16px",
      "line-height": "24px",
      "border-radius": "0",
      "background": _vm.type === 'diet_challenge' ? 'white' : 'superLightGray.900',
      "border-color": "green.500"
    },
    on: {
      "click": function click($event) {
        return _vm.loadContentChallengeByType('diet_challenge');
      }
    }
  }, [_c('c-image', {
    staticClass: "icon",
    attrs: {
      "alt": "daily",
      "src": _vm.type === 'diet_challenge' ? require('@/assets/icon-flag-active.svg') : require('@/assets/icon-flag.svg'),
      "w": "24px",
      "h": "24px",
      "mr": [null, '22px']
    }
  }), _vm._v(" Diet Challenge ")], 1)], 1), _c('c-box', [_c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": 'repeat(' + _vm.headings.length + ', 0fr)'
    }
  }, _vm._l(_vm.headings, function (item, index) {
    return _c('c-box', {
      key: 'heading-' + index,
      attrs: {
        "w": item.width,
        "py": "11px",
        "px": "16px",
        "color": "primary.400",
        "font-weight": "bold",
        "font-size": "12px",
        "line-height": "18px",
        "text-transform": "uppercase",
        "bg": "rgba(0, 140, 129, 0.1)"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1), _vm._l(_vm.items, function (item, index) {
    return _c('c-grid', {
      key: item.id,
      attrs: {
        "w": "100%",
        "max-w": "100%",
        "template-columns": 'repeat(' + _vm.headings.length + ', 0fr)',
        "border-bottom": "1px solid #C4C4C4"
      }
    }, [_c('c-box', {
      attrs: {
        "w": _vm.headings[0].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.numberingRow(index)) + " ")]), _c('c-box', {
      attrs: {
        "py": "16px",
        "px": "14px",
        "w": _vm.headings[1].width,
        "color": "black.900",
        "font-weight": "bold",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('c-box', {
      attrs: {
        "py": "16px",
        "px": "14px",
        "w": _vm.headings[2].width,
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center",
        "word-break": "break-word"
      }
    }, [_c('c-box', {
      attrs: {
        "min-w": "58px",
        "w": "58px",
        "h": "58px",
        "mr": "20px",
        "bg": "primary.300",
        "overflow": "hidden"
      }
    }, _vm._l(item.media, function (media, index_) {
      return _c('c-box', {
        key: 'img' + index_,
        attrs: {
          "w": "100%",
          "h": "100%"
        }
      }, [media.preview ? _c('c-image', {
        staticClass: "icon",
        attrs: {
          "alt": "daily",
          "w": "100%",
          "h": "100%",
          "object-fit": "cover",
          "src": _vm.getPhotoProgram(media.url)
        }
      }) : _vm._e()], 1);
    }), 1), _vm._v(" " + _vm._s(_vm.trimText(item.description, 73)) + " ")], 1), _c('c-box', {
      attrs: {
        "py": "16px",
        "px": "14px",
        "w": _vm.headings[3].width,
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.getLabelCategory(item.category)) + " ")]), _c('c-box', {
      attrs: {
        "w": _vm.headings[4].width,
        "py": "16px",
        "px": "14px",
        "color": "black.900",
        "font-weight": "normal",
        "font-size": "14px",
        "line-height": "21px",
        "d": "flex",
        "align-items": "center"
      }
    }, [_c('c-button', {
      attrs: {
        "h": "30px",
        "color": "primary.400",
        "border-radius": "24px",
        "border-color": "primary.400",
        "font-size": "12px",
        "line-height": "18px",
        "variant": "outline",
        "as": "router-link",
        "to": {
          name: 'superadmin.coaching-challenge.detail',
          params: {
            id: item.id
          }
        }
      }
    }, [_vm._v(" Lihat ")])], 1)], 1);
  }), !_vm.isEmpty ? _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "py": "28px",
      "px": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px"
    }
  }, [_vm._v(" Show: ")]), _c('c-select', {
    attrs: {
      "min-w": "120px",
      "border-radius": "6px",
      "font-size": "14px",
      "ml": "13px",
      "size": "md"
    },
    on: {
      "change": _vm.onSearch
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }, [_c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(" 5 Rows ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 Rows ")]), _c('option', {
    attrs: {
      "value": "15"
    }
  }, [_vm._v(" 15 Rows ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20 Rows ")])])], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end",
      "w": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "p": "0",
      "mr": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.currentPage === 1
    },
    on: {
      "click": _vm.prevPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (page_) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n                  ':hover': {\n                    bg: 'rgba(0, 140, 129, 0.3)',\n                    color: 'primary.400',\n                  },\n                }"
      }],
      key: page_,
      attrs: {
        "p": "0",
        "m": "0",
        "w": "32px",
        "h": "32px",
        "min-w": "32px",
        "mx": "4px",
        "border-radius": "30px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": page_ === _vm.currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: page_
          })
        }
      }
    }, [_vm._v(" " + _vm._s(page_) + " ")]);
  }), _c('c-button', {
    attrs: {
      "p": "0",
      "ml": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.page
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1) : _vm._e(), _vm.isEmpty ? _c('c-flex', {
    attrs: {
      "py": "80px",
      "px": "20px",
      "justify-content": "center"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty"
    }
  }), _c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-family": "Roboto",
      "font-size": "24px",
      "margin-top": "20px"
    }
  }, [_vm._v(" Konten tidak ditemukan ")])], 1)], 1) : _vm._e()], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }