<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Manajemen Diet Coaching & Challenge
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />

    <c-flex
      mb="33px"
      justify="space-between"
      align="center"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Manajemen Diet Coaching & Challenge
      </c-heading>
      <c-button
        h="auto"
        py="12px"
        px="28px"
        color="#927102"
        border-radius="24px"
        border-color="transparent"
        bg="secondary.800"
        variant-color="inherit"
        font-size="16px"
        line-height="24px"
        variant="solid"
        as="router-link"
        :to="{
          name: 'superadmin.coaching-challenge.add',
          params: { type: type },
        }"
      >
        <c-image
          :src="require('@/assets/icon-plus.svg')"
          alt="icon"
          display="inline-block"
          mr="10px"
        />
        Tambah Konten
      </c-button>
    </c-flex>
    <c-box
      background="#FFFFFF"
      border="1px solid #F2F2F2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="12px"
    >
      <c-flex
        py="25px"
        px="27px"
        border-bottom="1px solid #C4C4C4"
      >
        <c-flex
          justify="space-between"
          align="center"
          flex-grow="1"
        >
          <c-heading
            as="h3"
            font-family="Montserrat"
            font-weight="600"
            font-size="16px"
            line-height="24px"
          >
            List of Diet Coaching & Challenge
          </c-heading>
          <c-menu :close-on-select="false">
            <c-menu-button
              border-radius="40px"
              background-color="superLightGray.900"
              pl="20px"
              pr="30px"
              py="10px"
              h="auto"
              color="gray.900"
              font-size="14px"
              line-height="21px"
              font-family="Roboto"
              font-weight="normal"
              :display="['none', 'flex']"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                margin-right="5px"
              />
              Filter
            </c-menu-button>
            <c-menu-list
              z-index="10"
              min-width="240px"
            >
              <c-menu-option-group
                default-value="Semua"
                type="radio"
                @change="onChangeFilter"
              >
                <c-menu-item-option
                  v-for="item in filterItems"
                  :key="item.value"
                  :value="item.label"
                  border-bottom="1px solid #F2F2F2"
                  font-size="18px"
                  line-height="27px"
                  py="16px"
                  px="20px"
                >
                  {{ item.label }}
                </c-menu-item-option>
              </c-menu-option-group>
            </c-menu-list>
          </c-menu>
        </c-flex>
        <c-divider
          mx="23px"
          orientation="vertical"
        />
        <c-box
          flex-basis="248px"
          align-self="center"
        >
          <c-form-control
            pos="relative"
            w="100%"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              pos="absolute"
              top="10px"
              left="15px"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="search"
              v-chakra="{
                '&::placeholder': {
                  color: 'gray.900',
                },
              }"
              type="text"
              w="100%"
              placeholder="Pencarian"
              background="superLightGray.900"
              border-radius="6px"
              padding-left="50px"
              pos="relative"
              z-index="1"
              @keyup="onSearch"
            />
          </c-form-control>
        </c-box>
      </c-flex>

      <c-box>
        <c-flex>
          <c-button
            v-chakra="{
              '&:hover': {
                background: '#F2F2F2',
                borderRadius: '0',
              },
              '&:focus, &:active': {
                color: 'primary.400',
                background: 'white',
                borderRadius: '0',
                boxShadow: 'none',
              },
            }"
            size="md"
            height="58px"
            flex-grow="1"
            :color="type === 'daily_coaching' ? 'primary.400' : 'darkGray.900'"
            font-size="16px"
            line-height="24px"
            border-radius="0"
            :background="type === 'daily_coaching' ? 'white' : 'superLightGray.900'"
            border-color="green.500"
            @click="loadContentChallengeByType('daily_coaching')"
          >
            <c-image
              class="icon"
              alt="daily"
              :src="
                type === 'daily_coaching'
                  ? require('@/assets/icon-calendar-event-active.svg')
                  : require('@/assets/icon-calendar-event.svg')
              "
              w="24px"
              h="24px"
              :mr="[null, '22px']"
            />
            Daily Coaching
          </c-button>
          <c-divider
            m="0"
            border-color="lightGray"
            orientation="vertical"
          />
          <c-button
            v-chakra="{
              '&:hover': {
                background: '#F2F2F2',
                borderRadius: '0',
              },
              '&:focus, &:active': {
                color: 'primary.400',
                background: 'white',
                borderRadius: '0',
                boxShadow: 'none',
              },
            }"
            size="md"
            height="58px"
            flex-grow="1"
            :color="type === 'coaching_course' ? 'primary.400' : 'darkGray.900'"
            font-size="16px"
            line-height="24px"
            border-radius="0"
            :background="type === 'coaching_course' ? 'white' : 'superLightGray.900'"
            border-color="green.500"
            @click="loadContentChallengeByType('coaching_course')"
          >
            <c-image
              class="icon"
              alt="daily"
              :src="
                type === 'coaching_course'
                  ? require('@/assets/icon-target-active.svg')
                  : require('@/assets/icon-target.svg')
              "
              w="24px"
              h="24px"
              :mr="[null, '22px']"
            />
            Coaching Course
          </c-button>
          <c-divider
            m="0"
            border-color="lightGray"
            orientation="vertical"
          />
          <c-button
            v-chakra="{
              '&:hover': {
                background: '#F2F2F2',
                borderRadius: '0',
              },
              '&:focus, &:active': {
                color: 'primary.400',
                background: 'white',
                borderRadius: '0',
                boxShadow: 'none',
              },
            }"
            size="md"
            height="58px"
            flex-grow="1"
            :color="type === 'diet_challenge' ? 'primary.400' : 'darkGray.900'"
            font-size="16px"
            line-height="24px"
            border-radius="0"
            :background="type === 'diet_challenge' ? 'white' : 'superLightGray.900'"
            border-color="green.500"
            @click="loadContentChallengeByType('diet_challenge')"
          >
            <c-image
              class="icon"
              alt="daily"
              :src="
                type === 'diet_challenge' ? require('@/assets/icon-flag-active.svg') : require('@/assets/icon-flag.svg')
              "
              w="24px"
              h="24px"
              :mr="[null, '22px']"
            />
            Diet Challenge
          </c-button>
        </c-flex>

        <c-box>
          <c-grid
            w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
          >
            <c-box
              v-for="(item, index) in headings"
              :key="'heading-' + index"
              :w="item.width"
              py="11px"
              px="16px"
              color="primary.400"
              font-weight="bold"
              font-size="12px"
              line-height="18px"
              text-transform="uppercase"
              bg="rgba(0, 140, 129, 0.1)"
            >
              {{ item.label }}
            </c-box>
          </c-grid>
          <c-grid
            v-for="(item, index) in items"
            :key="item.id"
            w="100%"
            max-w="100%"
            :template-columns="'repeat(' + headings.length + ', 0fr)'"
            border-bottom="1px solid #C4C4C4"
          >
            <c-box
              :w="headings[0].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ numberingRow(index) }}
            </c-box>
            <c-box
              py="16px"
              px="14px"
              :w="headings[1].width"
              color="black.900"
              font-weight="bold"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ item.title }}
            </c-box>
            <c-box
              py="16px"
              px="14px"
              :w="headings[2].width"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
              word-break="break-word"
            >
              <c-box
                min-w="58px"
                w="58px"
                h="58px"
                mr="20px"
                bg="primary.300"
                overflow="hidden"
              >
                <c-box
                  v-for="(media, index_) in item.media"
                  :key="'img' + index_"
                  w="100%"
                  h="100%"
                >
                  <c-image
                    v-if="media.preview"
                    class="icon"
                    alt="daily"
                    w="100%"
                    h="100%"
                    object-fit="cover"
                    :src="getPhotoProgram(media.url)"
                  />
                </c-box>
              </c-box>
              {{ trimText(item.description, 73) }}
            </c-box>
            <c-box
              py="16px"
              px="14px"
              :w="headings[3].width"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              {{ getLabelCategory(item.category) }}
            </c-box>
            <c-box
              :w="headings[4].width"
              py="16px"
              px="14px"
              color="black.900"
              font-weight="normal"
              font-size="14px"
              line-height="21px"
              d="flex"
              align-items="center"
            >
              <c-button
                h="30px"
                color="primary.400"
                border-radius="24px"
                border-color="primary.400"
                font-size="12px"
                line-height="18px"
                variant="outline"
                as="router-link"
                :to="{
                  name: 'superadmin.coaching-challenge.detail',
                  params: { id: item.id },
                }"
              >
                Lihat
              </c-button>
            </c-box>
          </c-grid>
          <c-flex
            v-if="!isEmpty"
            justify="space-between"
            py="28px"
            px="40px"
          >
            <c-flex align="center">
              <c-text
                color="gray.900"
                font-size="14px"
                line-height="21px"
              >
                Show:
              </c-text>
              <c-select
                v-model="perPage"
                min-w="120px"
                border-radius="6px"
                font-size="14px"
                ml="13px"
                size="md"
                @change="onSearch"
              >
                <option value="5">
                  5 Rows
                </option>
                <option value="10">
                  10 Rows
                </option>
                <option value="15">
                  15 Rows
                </option>
                <option value="20">
                  20 Rows
                </option>
              </c-select>
            </c-flex>

            <c-flex
              justify-content="flex-end"
              w="100%"
            >
              <c-flex
                justify-content="center"
                align="center"
              >
                <c-button
                  p="0"
                  mr="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="currentPage === 1"
                  @click="prevPage"
                >
                  <c-icon
                    name="chevron-left"
                    size="24px"
                  />
                </c-button>

                <c-button
                  v-for="page_ in pages"
                  :key="page_"
                  v-chakra="{
                    ':hover': {
                      bg: 'rgba(0, 140, 129, 0.3)',
                      color: 'primary.400',
                    },
                  }"
                  p="0"
                  m="0"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  mx="4px"
                  border-radius="30px"
                  background-color="rgba(0, 140, 129, 0.3)"
                  color="primary.400"
                  :bg="page_ === currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent']"
                  as="router-link"
                  :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
                >
                  {{ page_ }}
                </c-button>

                <c-button
                  p="0"
                  ml="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="pages.length === page"
                  @click="nextPage"
                >
                  <c-icon
                    name="chevron-right"
                    size="24px"
                  />
                </c-button>
              </c-flex>
            </c-flex>
          </c-flex>
          <c-flex
            v-if="isEmpty"
            py="80px"
            px="20px"
            justify-content="center"
          >
            <c-box>
              <c-image
                :src="require('@/assets/empty-nutritionists.svg')"
                alt="empty"
              />
              <c-text
                color="gray.900"
                font-family="Roboto"
                font-size="24px"
                margin-top="20px"
              >
                Konten tidak ditemukan
              </c-text>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'ManagementCoachingChallengePage',
  components: {},
  mixins: [generalMixin],
  data() {
    return {
      headings: [
        { label: 'no', width: '82px' },
        { label: 'judul konten', width: '200px' },
        { label: 'preview', width: '360px' },
        { label: 'kategori', width: '140px' },
        { label: 'action', width: '110px' },
      ],
      page: 1,
      perPage: '10',
      search: this.$route.query.q ?? '',
      selectedCategory: '',
      timer: null,
      type: this.$route.query.type ?? 'daily_coaching',
    }
  },
  computed: {
    ...mapState({
      category: (s) => s.contentChallenge.category,
      categoryCoaching: (s) => s.contentChallenge.categoryCoaching,
    }),
    ...mapGetters({
      items: 'contentChallenge/items',
      meta: 'contentChallenge/meta',
    }),
    isEmpty() {
      return this.items.length === 0
    },
    filterItems() {
      if (this.type === 'coaching_course') {
        return this.categoryCoaching
      }
      return this.category
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.meta.total ?? 1
      let perPage = parseInt(this.perPage) ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1
        this.type = route.query.type ?? this.type
        let params = new URLSearchParams()
        params.set('q', this.search)
        params.set('type', this.type)
        params.set('category', route.query.category ?? '')
        params.set('page', this.page)
        params.set('perpage', this.perPage)
        this.loadContentChallenge(params)
      },
    },
  },
  methods: {
    ...mapActions({
      loadContentChallenge: 'contentChallenge/listContentChallengeSuperadmin',
    }),
    getLabelCategory(value) {
      if (this.type === 'coaching_course') {
        return this.categoryCoaching.find((dt) => dt.value === value)?.label
      }
      return this.category.find((dt) => dt.value === value)?.label
    },
    loadContentChallengeByType(type) {
      this.type = type
      this.onSearch()
    },
    onSearch() {
      this.page = 1
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            q: this.search,
            type: this.type,
            category: this.selectedCategory.value,
            page: this.page,
            perpage: this.perPage,
          },
        })
      }, 800)
    },
    numberingRow(index) {
      return this.page * this.perPage - this.perPage + index + 1
    },
    onChangeFilter(e) {
      if (this.type === 'coaching_course') {
        this.selectedCategory = this.categoryCoaching.find((it) => it.label === e)
      } else {
        this.selectedCategory = this.category.find((it) => it.label === e)
      }
      this.onSearch()
    },
    prevPage() {
      this.page -= 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    nextPage() {
      this.page += 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
  },
}
</script>
